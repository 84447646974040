<template>
  <div class="content content3 flex-column" style="padding: 10px">
    <div class="cont flex-1-auto">
      <el-table
          ref="multipleTable"
          :data="securityList"
          height="100%"
          style="width: 100%"
      >
        <el-table-column align="center" type="index" label="序号" width="80"></el-table-column>
        <el-table-column
            prop="checkPartName"
            label="检查部位"
        ></el-table-column>
        <el-table-column
            prop="name"
            label="检查项目"
        ></el-table-column>
      </el-table>
    </div>
    <div class="flex-center" style="padding-top: 10px">
      <el-pagination
          v-if="userListTotal > 10"
          background
          :current-page.sync="searchUserValue.pageCurrent"
          :page-size="searchUserValue.pageSize"
          :total="userListTotal"
          layout="total, prev, pager, next"
          @current-change="handleSizeChangeCheckData"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import Apis from "@/views/menus/api/system-setup";
export default {
  name: "IndustrialPark",

  components: {},

  props: {},

  data() {
    return {
      searchUserValue: {
        pageCurrent: 1,
        pageSize: 10
      },
      userListTotal: 0,
      securityList:[],
    }
  },

  computed: {},

  watch: {},

  mounted() {
    this.getSecurityList()
  },

  methods: {
    // init() {
    // }
    async getSecurityList() {
      const data = await Apis.findSafetyList(this.searchUserValue)
      this.securityList = data.list
      this.userListTotal = data.total
      // this.tableDatas = data.list
      // TODO
    },
    async handleSizeChangeCheckData(val) {
      // console.warn(val)
      this.searchUserValue.pageCurrent = val
      this.getSecurityList()
    },
  }
}
</script>

<style lang="scss" scoped>

</style>
